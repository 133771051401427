<template>
  <div class=" bg-white flex justify-center items-center flex-col">
     <audio controls src="https://stream.bauermedia.fi/classic/classic_128.mp3" id="audio_src" class="hidden" @volumechange="onVolumeChange($event)"> </audio>
     <div v-show="stations.length>1" class="bg-gray-800 h-16 w-72 md:w-80 rounded-t-lg p-2 flex justify-center items-center shadow-md">
        <h1 class="text-center text-3xl font-bold m-4 text-white">Antoris Radio</h1>
     </div>
     <div class="w-full flex justify-center items-center p-6">
      <img src="https://images.pexels.com/photos/1044989/pexels-photo-1044989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" class="absolute h-96 md:h-128 w-full">
      <div class="bg-gray-900 relative w-80 h-80 md:w-96 md:h-96 shadow-md">      
         <img class="object-cover absolute min-h-full min-w-full" :src="stations[currentStation].cover">
         <div class="bg-gradient-to-t from-black to-transparent h-full w-full absolute">         
         </div>
         <div class="h-12 w-full absolute bottom-20">
            <Visualizer  v-for="(visualizerType,index) in visualizerTypes" :key="index" audioId="audio_src" :type="visualizerType" :componentId="index" class="bg-gradient-to-t from-black to-transparent w-full h-12"/>
         </div>

         <div class="h-20 w-full absolute bottom-0">
            <div class="bg-black bg-opacity-50 flex">
               <button v-show="isPlaying" @click="setStation(currentStation,false)" class="text-gray-300 hover:text-gray-100 rounded-full w-20 h-20 flex justify-center items-center">
                  <svg v-show="isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
               </button>
               <button v-show="!isPlaying" @click="setStation(currentStation,true)" class="text-gray-300 hover:text-gray-100 rounded-full w-20 h-20 flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
               </button>
               <div class="flex flex-col justify-center">
                  <h2 class="text-3xl font-bold text-white "> {{stations[currentStation].name}}</h2>
                  <p class="text-white text-xs pb-1 block">{{songName}}</p>
               </div>

            </div>

         </div>

      </div>
    </div>
    <div v-show="stations.length>1" class="bg-gray-800 h-16 w-72 md:w-80 rounded-b-lg p-2 flex justify-center items-center shadow-md">
      <div class="inline-block relative">
         <button @click="stationsMenuOpen=!stationsMenuOpen" class="shadow-lg border text-white bg-white bg-opacity-10 hover:bg-opacity-20 m-2 p-3 rounded-full w-12 h-12 flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /> </svg>
         </button>
         
         <!-- <a href="#" @click="stationsMenuOpen=!stationsMenuOpen" @hover="stationsMenuOpen=true" class="p-2 bg-white">^</a> -->
         <ul id="" class="absolute bottom-14" v-show="stationsMenuOpen">
            <li v-for="station in stations" :key="station.id" class="m-0 ">
               <a href="#" @click.prevent="setStation(station.id)" class="px-6 py-2 text-white border block whitespace-nowrap bg-gray-700 hover:bg-gray-800 bg-opacity-90 font-semibold">{{ station.name }}</a>
                <!-- {{ station.id }} - - {{ station.url }} -->
            </li>
         </ul>
      </div> 
      <button @click="previousStaion" class="shadow-lg border text-white bg-white bg-opacity-10 hover:bg-opacity-20 m-2 p-3 rounded-full w-12 h-12 flex justify-center items-center">
         <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" /> </svg>
      </button>
      <button @click="nextStaion" class="shadow-lg border text-white bg-white bg-opacity-10 hover:bg-opacity-20 m-2 p-3 rounded-full w-12 h-12 flex justify-center items-center">
         <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" /> </svg>
      </button>
    </div>
    <div id="articles" class="w-full container mx-auto">
      <div class="container mx-auto px-4">
         <h2 class="mb-6 mt-12 pb-2 border-b-4 border-stone-100 w-full">
         <span class="text-2xl border-b-4 border-primary pb-1"> {{title1}} </span>
         </h2>
         <div id="articles1"></div>
         <h2 class="mb-6 mt-12 pb-2 border-b-4 border-stone-100 w-full">
         <span class="text-2xl border-b-4 border-primary pb-1"> {{title2}} </span>
         </h2>
         <div id="articles2"></div>
      </div>
    </div>
  </div>
</template>

<script>
   import Wave from "@foobar404/wave"
   import axios from 'axios'

   import Visualizer from './Visualizer.vue'

  export default {
   components: {
      Visualizer,
   },
  data() {
    return { 
         title1:'-',
         title2:'-',
       isPlaying:false,
       brojac:50,
       stationsMenuOpen: false,
       songName:'',
      visualizerTypes: [
        'bars'//,'shine','shockwave','orbs'
      ],
      currentStation: 0,
      stations : [
         { 
            id:0, 
            name: 'Antoris Radio', 
            // url:'https://assets.antoris.mk/stream/antoris.mp3',
            url:'http://82.214.116.181:8000/;?type=http&nocache=1',
            cover: 'http://assets.antoris.kmtest.eu/antoris-radio.png',
            songUrl:'http://radio.antoris.mk/song.php',
          },
         // { 
         //    id:1, 
         //    name: 'ClassicFM', 
         //    url:'https://stream.bauermedia.fi/classic/classic_128.mp3',
         //    cover: 'http://assets.antoris.mk/classical.jpg',
         //    songUrl: '',
         //  },
         // { 
         //    id:2, 
         //    name: 'Antenna 5', 
         //    url:'http://antenna5stream.neotel.mk:8000/live128',
         //    cover: 'http://assets.antoris.mk/antenna5.png',
         //    songUrl: '',
         //  },
         // { 
         //    id:3, 
         //    name: 'ImeldaFM', 
         //    url:'https://server.radioimeldafm.co.id/radio/8000/imeldafm',
         //    cover: 'http://assets.antoris.mk/music.jpg',
         //    songUrl: '',
         //  },
         // { 
         //    id:4, 
         //    name: 'Vanilla Radio', 
         //    url:'https://smooth.vanillaradio.com:8006/live',
         //    cover: 'http://assets.antoris.mk/vanillaradio.jpg',
         //    songUrl: '',
         //  },
         // { 
         //    id:5, 
         //    name: 'Planet Rock', 
         //    url:'https://stream-mz.planetradio.co.uk/planetrock.mp3',
         //    cover: 'http://assets.antoris.mk/rock.jpg',
         //    songUrl: '',
         //  },
      ]

    }
  },
   computed: {
      // isPlaying() {
      //    // let audionEl=document.getElementById("audio_src");
      //    // return audionEl.paused; 
      //    return true;
      // }
   },
   methods: {
      onVolumeChange ($event) {
         console.log($event);
         console.log(document.getElementById("audio_src").volume);
      },
      setStation(newStation,toPlay=true){
         this.currentStation=newStation;
         let audioControl=document.getElementById("audio_src");
         audioControl.pause();
         // audioControl.volume=0.5;
         audioControl.setAttribute('src', this.stations[newStation].url);
         audioControl.load();
         if (toPlay) {
            audioControl.play();            
         }
         this.isPlaying=toPlay;
         this.stationsMenuOpen=false;
         this.songName='';
      },
      nextStaion() {
         this.setStation((this.currentStation+1) % this.stations.length);
      },
      previousStaion() {
         this.setStation((this.currentStation==0)?this.stations.length-1:(this.currentStation-1) % this.stations.length);
      }
   },
    props: {
      audioId: {
        type: String,
      },
      type: {
        type: String,
      },
      componentId: {
        type: String,
      },
    },
    mounted() {
      let wave = new Wave();
      wave.fromElement(this.audioId, "output-"+this.componentId, {
            type: this.type,
            // colors: ["red", "white","#f00", "blue"]
            });
      // this.setStation(0,true);
      setInterval(() => {
         if (this.isPlaying&&this.stations[this.currentStation].songUrl!='') {
            axios.get(this.stations[this.currentStation].songUrl)
                  .then((result) => {
                     // console.log(result.data.song);
                     this.songName=result.data.song;
                  })
         }
      }, 3000);
    },
    created () {
      this.title1=window.title1;
      this.title2=window.title2;
      axios.get('http://radio.antoris.mk/articles.php?tag='+window.tag1)
            .then((result) => {
               // console.log(result);
               this.content1=result.data;
               let text = result.data;
               let resultContent = text.replace("\"/files/", "\"http://antoris.mk/files/");
               document.getElementById("articles1").innerHTML=resultContent; 
            })
      axios.get('http://radio.antoris.mk/articles.php?tag='+window.tag2)
            .then((result) => {
               // console.log(result);
               this.content1=result.data;
               let text = result.data;
               let resultContent = text.replace("\"/files/", "\"http://antoris.mk/files/");
               document.getElementById("articles2").innerHTML=resultContent;
            })

    },
  }
</script>

<style lang="css" scoped>

</style>