<template>
  <Radio />
</template>

<script>
import Radio from './components/Radio.vue'

export default {
  name: 'App',
  components: {
    // Visualizer,
    Radio
  },
  methods: {
    // increment() {
    //   // `this` will refer to the component instance
    //   this.count++
    // }
  }
}
</script>

<style>
</style>
