<template>
  <canvas :id="'output-'+componentId" sty2le="background-image2: url('https://images.pexels.com/photos/10881707/pexels-photo-10881707.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260')" >
  </canvas>
</template>

<script>
  import Wave from "@foobar404/wave"
  export default {

    props: {
      audioId: {
        type: String,
      },
      type: {
        type: String,
      },
      componentId: {
        type: Number,
      },
    },
    mounted() {
      let wave = new Wave();
      wave.fromElement(this.audioId, "output-"+this.componentId, {
            type: this.type,
            colors: ['#a26114','#b17b39','#be915a','#d0af87','#e5d3bd','#f3ebe0','#ffffff']
            });
    },
  }
</script>

<style lang="scss" scoped>

</style>